export default class FrontPageEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'

    //Strate Map
    static BEACH_ON_MAP = '[data-element="beach"]'
    static MODAL = '[data-element="details-map-modal"]'
    static CLOSE_MODAL = '[data-element="close-details-map-modal"]'

    //Strate Timeline
    static STRATE_TIMELINE = '[data-element="strate-timeline"]'
    static STRATE_TIMELINE_NEXT = '[data-pagination="strate-timeline-arrow-next"]'
    static STRATE_TIMELINE_PREV = '[data-pagination="strate-timeline-arrow-prev"]'
    static STRATE_TIMELINE_PROGRESS_BAR = '[data-pagination="strate-timeline-progress-bar"]'

    //Strate Events
    static STRATE_EVENTS = '[data-element="strate-events"]'
    static STRATE_EVENTS_NEXT = '[data-pagination="strate-events-arrow-next"]'
    static STRATE_EVENTS_PREV = '[data-pagination="strate-events-arrow-prev"]'

    //Animations
    static REVEAL_ELEMENT = '[data-customer-animation]'
}
