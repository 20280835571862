import FrontPageEnum from '../../enumerators/front-page.enum'

export default class StrateMapComponent {
    constructor() {
        if ($(FrontPageEnum.BEACH_ON_MAP).length) {
            $(FrontPageEnum.BEACH_ON_MAP).on('click', function () {
                const beachId = $(this).data('beach-id')

                StrateMapComponent.clearModalContent()

                if (!$(FrontPageEnum.MODAL).hasClass('loaded')) {
                    $(FrontPageEnum.MODAL).attr('data-beach-id', beachId)
                    StrateMapComponent.getDetailMapData(beachId)
                    $(FrontPageEnum.MODAL).addClass('loaded')
                }

                $(FrontPageEnum.MODAL).addClass('open')
                return false
            })
        }
    }

    static getDetailMapData(beachId) {
        //console.log(beachId);
        $.ajax({
            url: IRISCollectionTheme.ajaxUrl,
            data: {
                action: 'get_details_map',
                datas: {
                    beach_id: beachId,
                },
            },
            method: 'POST',
        })
            .done((response) => {
                // console.log("success", response);
                if (!$(FrontPageEnum.MODAL).hasClass('modal-loaded')) {
                    $(FrontPageEnum.MODAL).html(response).addClass('modal-loaded')
                }
                StrateMapComponent.renderMapModal('#details-map-modal')
            })
            .fail((response) => {
                //console.log("error", response);
            })
    }

    static renderMapModal(element) {
        $(FrontPageEnum.CLOSE_MODAL).on('click', () => {
            $('.custom-modal').removeClass('open')
        })
        $(element).removeClass('loading')
        $(element).find('.loader').remove()
        $(document).trigger('iris-map/map/load')
        StrateMapComponent.closeCardMap()
    }

    static closeCardMap() {
        $(FrontPageEnum.MODAL).removeAttr('data-beach-id')
        $(FrontPageEnum.MODAL).removeClass('loaded')
        $(FrontPageEnum.MODAL).removeClass('modal-loaded')
        $(FrontPageEnum.MODAL).addClass('loading')
    }

    static clearModalContent() {
        $(FrontPageEnum.MODAL).html('')
    }
}
