import StrateMapComponent from '../components/front-page/strate-map.component'

export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            import(
                '@scripts/components/front-page/strate-map.component' /* webpackChunkName: "scripts/strate-map.component" */
            ).then(({ default: StrateMapComponent }) => {
                new StrateMapComponent()
            })

            import(
                '@scripts/components/front-page/slider-home.component' /* webpackChunkName: "scripts/slider-home.component" */
            ).then(({ default: SliderHomeComponent }) => {
                new SliderHomeComponent()
            })

            if (window.matchMedia('(min-width: 999px)').matches) {
                import(
                    '@scripts/services/animation.service' /* webpackChunkName: "scripts/animation.service" */
                ).then(({ default: AnimationService }) => {
                    new AnimationService()
                })
            }
        }
    }
}
